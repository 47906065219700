import { Box, ButtonGroup, Card } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useContext, useState } from "react";
import Header from "../../components/misc/header";
import TableWrapper from "../../components/misc/tableWrapper";
import { PanelSessionContext } from "../../components/sessionContext";
import { ConnectRootPermissions } from "../../utils/types";
import { AccessCard } from "./access/accessCard";
import { AccountDialogs } from "./accountDialogs";
import AccountCreationWizzard from "./access/accountWizzard";
import { SettingsContext } from "../../components/settingsContext";

const AccountsPage = () => {
    const session = useContext(PanelSessionContext);
    const settings = useContext(SettingsContext);

    const [selection, setSelection] = useState<number[]>([]);

    const columns = [
        { field: "id", headerName: "ID" },
        { field: "name", headerName: "Name", flex: 1},
        { field: "api", headerName: "API Token", valueGetter: (params:any) => params.row.api || "-"},
        { field: "email", headerName: "E-Mail", flex: .5, valueGetter: (params:any) => params.row.email || "-"},
        { field: "sessions", headerName: "Sessions", valueGetter: (params:any) => params.row.sessions.length ? params.row.sessions.length + " Session/s" : "No Session"},
        { field: "access", headerName: "Accessable Containers", flex: .25, valueGetter: (params:any) => (params.row.permissions as string[]).includes(ConnectRootPermissions.ROOT.ident) ? "All" : (params.row.access.length ? params.row.access.length + " Container/s" : "No Access")},
        { field: "permissions", headerName: "Root-Account", valueGetter: (params:any) => (params.row.permissions as string[]).includes(ConnectRootPermissions.ROOT.ident) ? "Yes" : "No"},
    ]


    return <Box m="20px" display="flex" flexDirection="column" gap={2}>
        <Header title="Accounts" subtitle="Manage your accounts here." />
        <Card sx={{p: "20px"}}>
            <ButtonGroup variant="contained">
                { settings.devMode && <AccountDialogs.CreateAccount /> }
                <AccountCreationWizzard />
                { selection.length === 1 && <AccountDialogs.EditAccount ids={selection} />}
                { selection.length > 0 && <AccountDialogs.DeleteAccount ids={selection} />}
            </ButtonGroup>

            <TableWrapper>
                <DataGrid rows={session.accounts} columns={columns} sortModel={[{ field: "name", sort: "asc" }]} onRowSelectionModelChange={(e:any) => setSelection(e)} checkboxSelection />
            </TableWrapper>
        </Card>
        <AccessCard />
    </Box>
}

export default AccountsPage;