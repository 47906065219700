import { AppRegistration, Home, Inventory, Person2 } from "@mui/icons-material";
import DensityMediumOutlinedIcon from "@mui/icons-material/DensityMediumOutlined";
import FileCopyIcon from '@mui/icons-material/FileCopy';
import HandymanIcon from '@mui/icons-material/Handyman';
import LogoutIcon from '@mui/icons-material/Logout';
import PeopleIcon from "@mui/icons-material/People";
import { Box, Typography, useTheme } from "@mui/material";
import { ReactElement, useContext, useState } from "react";
import { Menu, MenuItem, Sidebar } from "react-pro-sidebar";
import { useNavigate } from "react-router-dom";
import { PanelSessionContext } from "../../components/sessionContext";
import { getColors } from "../../theme";
import { ConnectORPermissions, ConnectRootPermissions, PROTECTED } from "../../utils/types";
import { UtilityFunctions } from "../../utils/utilityFunctions";

type ItemProps = {
    title:string
    to:string
    icon:ReactElement
    selected:string
    setSelected:(title:string) => void
}

const Item = ({title, to, icon, selected, setSelected}:ItemProps) => {
    const theme = useTheme();
    const colors = getColors(theme.palette.mode);
    const nav = useNavigate();

    return <MenuItem onClickCapture={() => nav(to)} active={selected === title} style={{ color: colors.gray[100] }} icon={icon} onClick={e => setSelected(title)} >
        <Typography fontWeight={"bold"}>{title}</Typography>
    </MenuItem>
}

const SidebarComponent = () => {
    const theme = useTheme();
    const colors = getColors(theme.palette.mode);
    const session = useContext(PanelSessionContext);

    const [isCollapsed, setIsCollapsed] = useState(false);
    const [selected, setSelected] = useState("Dashboard");

    return <Sidebar backgroundColor={colors.primary[400]} collapsed={isCollapsed} rootStyles={{
        borderColor: colors.primary[400],
        "&.ps-collapsed": {
            minWidth: "60px",
            width: "60px"
        }
    }}>
        <Menu style={{height: "100%"}} menuItemStyles={{
            button: {
                "&:hover": {
                    color: "#868dfb !important",
                    backgroundColor: colors.primary[400]
                },
                "&:active": {
                    color: colors.greenAccent[400],
                },
                "&.ps-active": {
                    backgroundColor: colors.primary[700],
                },
                padding: "5px 35px 5px 10px",
                transition: ".25s",
                borderRadius: "20px",
                margin: isCollapsed ? "0" : "0 10px"
            },
            subMenuContent: {
                backgroundColor: colors.primary[400],
                paddingLeft: "10px"
            }
        }}>
            <Box style={{display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%"}}>
                <Box>
                    <MenuItem icon={<DensityMediumOutlinedIcon />} onClick={e => setIsCollapsed(!isCollapsed)} ></MenuItem>

                    {!isCollapsed && <Typography variant="h6" color={colors.gray[200]} sx={{m: "15px 0 5px 15px"}}>Personal</Typography>}
                    <Item to="/" icon={<Person2 />} selected={selected} setSelected={setSelected} title="Profile" />
                    
                    { session.logged && (session.logged.permissions.includes(ConnectRootPermissions.ROOT.ident) || session.logged.access.find(a => a.containerId === PROTECTED.Container.id)?.permissions.some(p => p.ident.includes(ConnectORPermissions.CONTAINER_ADMIN.ident))) &&
                    <>
                        {!isCollapsed && <Typography variant="h6" color={colors.gray[200]} sx={{m: "15px 0 5px 15px"}}>Administration</Typography>}
                        <Item title="Accounts" to="/accounts" icon={<PeopleIcon />} selected={selected} setSelected={setSelected} />
                        { session.logged?.permissions.includes(ConnectRootPermissions.ROOT.ident) ? <>
                            <Item title="Asset Types" to="/assettypes" icon={<HandymanIcon />} selected={selected} setSelected={setSelected} />
                            <Item title="Assets" to="/assets" icon={<FileCopyIcon />} selected={selected} setSelected={setSelected} />
                            <Item title="Apps" to="/apps" icon={<AppRegistration />} selected={selected} setSelected={setSelected} />
                            <Item title="Containers" to="/containers" icon={<Inventory />} selected={selected} setSelected={setSelected} />
                        </> : <></>} 
                    </>
                    }
                    
                </Box>
                <Box>
                    <MenuItem icon={<LogoutIcon />} onClick={UtilityFunctions.logout} >Logout</MenuItem>
                </Box>
            </Box>

        </Menu>
    </Sidebar>
}

export default SidebarComponent;