import { Box, ButtonGroup, Card, Grid } from "@mui/material";
import { DataGrid, GridRowSelectionModel } from "@mui/x-data-grid";
import { FC, useContext, useState } from "react";
import Header from "../../components/misc/header";
import TableWrapper from "../../components/misc/tableWrapper";
import { PanelSessionContext } from "../../components/sessionContext";
import { AssetTypeDialogs } from "./assetTypeDialogs";
import { PROTECTED } from "../../utils/types";
import AssetTypeEditor from "./assettypeEditor";

const AssetTypePage:FC = () => {
    const session = useContext(PanelSessionContext);

    const [selection, setSelection] = useState<number[]>([]);

    const columns = [
        { field: "id", headerName: "ID" },
        { field: "name", headerName: "Name", flex: 1},
        { field: "fields", headerName: "Fields", valueGetter: (params:any) => params.row.fields.length ? params.row.fields.length + " Field/s" : "No Field", flex: 0.5},
    ]

    const splitView = selection.length === 1 && selection[0] !== PROTECTED.AssetType.id ;
    return <Box m="20px">
        <Header subtitle="List your Asset-Types managed by seanet-connect" title="ASSET-TYPES" />
        <Card sx={{p: "20px"}}>
            <Grid container spacing={2}>
                <Grid item xs={splitView ? 6 : 12}>
                    <ButtonGroup variant="contained" disabled={selection.includes(PROTECTED.AssetType.id)}>
                        <AssetTypeDialogs.Create />
                        { selection.length === 1 && <AssetTypeDialogs.Edit id={selection[0]} /> }
                        { selection.length && <AssetTypeDialogs.Delete ids={selection} />}
                    </ButtonGroup>
                    <TableWrapper>
                        <DataGrid rows={session.assetTypes} sortModel={[{ field: "name", sort: "asc" }]} columns={columns} onRowSelectionModelChange={(e:GridRowSelectionModel) => setSelection(e as number[])} rowSelectionModel={selection} checkboxSelection />
                    </TableWrapper>
                </Grid>
                {splitView && <Grid item xs={6}>
                    <AssetTypeEditor id={selection[0]} />
                </Grid>}
            </Grid>
            
        </Card>
    </Box>
}

export default AssetTypePage;