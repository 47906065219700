import { Add, EditNote, Remove } from "@mui/icons-material";
import { Box, Button, ButtonGroup, Tab, Tabs } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useContext, useMemo, useState } from "react";
import { TabPanel } from "../../components/misc/tabPanel";
import TableWrapper from "../../components/misc/tableWrapper";
import { PanelSessionContext } from "../../components/sessionContext";
import { Object, PROTECTED } from "../../utils/types";
import { ContainerAssetDialogs } from "./containerAssetDialogs";
import TabsWrapper from "../../components/misc/tabsWrapper";

const ContainerEditor = ({id}:{id:number}) => {
    const session = useContext(PanelSessionContext);

    const [selection, setSelection] = useState<number[]>([]);
    const [tab, setTab] = useState<number>(0);

    const assetColumns = [
        { field: "id", headerName: "ID" },
        { field: "name", headerName: "Name", flex: 1, valueGetter: (params:any) => session.assets.find(a => a.id === params.row.id)?.name},
    ]

    const appColumns = [
        { field: "id", headerName: "ID" },
        { field: "name", headerName: "Name", flex: 1, valueGetter: (params:any) => session.apps.find(a => a.id === params.row.id)?.name},
    ]

    const updateTab = (tab:number) => {
        setSelection([]);
        setTab(tab);
    }

    const container = useMemo(() => session.containers.find(c => c.id === id), [id, session.containers]);
    if (!container) return <Box>Container not found.</Box>
    return <Box>
        <Box display="flex" gap={5}>
            <TabsWrapper>
                <Tabs value={tab} onChange={(e, value) => updateTab(value)}>
                    <Tab label="Assets" id="assets" />
                    <Tab label="Apps" id="apps" />
                </Tabs>
            </TabsWrapper>
            <ButtonGroup variant="contained" disabled={id === PROTECTED.Container.id}>
                <ContainerAssetDialogs.Create containerId={id} appMode={tab} />
                <ContainerAssetDialogs.Delete containerId={id} appMode={tab} ids={selection} />
            </ButtonGroup>
        </Box>

        <TabPanel index={0} value={tab}>
            <TableWrapper>
                <DataGrid sortModel={[{ field: "name", sort: "asc" }]} rows={container.assets} columns={assetColumns} checkboxSelection onRowSelectionModelChange={e => setSelection(e as number[])} rowSelectionModel={selection} hideFooter />
            </TableWrapper>
        </TabPanel>

        <TabPanel index={1} value={tab}>
            <TableWrapper>
                <DataGrid sortModel={[{ field: "name", sort: "asc" }]} rows={container.apps} columns={appColumns} checkboxSelection onRowSelectionModelChange={e => setSelection(e as number[])} rowSelectionModel={selection} hideFooter />
            </TableWrapper>
        </TabPanel>
    </Box>
}

export default ContainerEditor;