import { Box, Button, CircularProgress, TextField, Typography, useTheme } from "@mui/material";
import axios from "axios";
import { Formik } from "formik";
import { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import * as yup from "yup";
import { PanelSessionContext } from "../../../components/sessionContext";
import { getColors } from "../../../theme";
import { UtilityFunctions } from "../../../utils/utilityFunctions";
import { addNotification } from "../../global/notificationWrapper";

const intialValues = { username: "", password: "", secondFactorCode: "" };
const validationSchema = yup.object({
    username: yup.string().required("Username is required").max(20, "Username must be at most 20 characters"),
    password: yup.string().required("Password is required").min(4, "Password must be at least 8 characters"),
    secondFactorCode: yup.string()
})

const PanelAuthentication = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [pending, setPending] = useState(false);
    const [secondFactorRequired, setSecondFactorRequired] = useState(false);
    const sessionPanel = useContext(PanelSessionContext);
    const theme = useTheme();
    const color = getColors(theme.palette.mode);

    useEffect(() => {
        document.getElementsByTagName("form")[0].style.width = "100%";
       
    }, []);

    const sendLogin = (values:{username:string, password:string, reqFrom?:string, secondFactorCode:string}) => {
        setPending(true);

        if (searchParams.get("reqFrom") !== null) {
            values.reqFrom = searchParams.get("reqFrom")!;
        }

        axios.post(process.env.REACT_APP_API_URL + "/authentication/login", {...values}, { withCredentials: true }).then(response => {
            setPending(false);
            if (response.data.success) {
                if (values.reqFrom !== undefined) {
                    UtilityFunctions.redirectToExternalURL(values.reqFrom, { session: response.data.response});
                } else {
                    window.location.reload();
                }
            } else {
                if (response.data.error === "2fa required") {
                    setSecondFactorRequired(true);
                    return;
                }
                console.log(response.data);
                addNotification({message: "Invalid Credentials!", severity: "error"});
            }
        });
    }

    
    const logged = !!sessionPanel.logged;
    const width = 350, height = (logged ? 450 : 390) + (secondFactorRequired ? 30 : 0);
    return <>
        <Box  width={800 + "px"} height={500 + "px"} position="absolute" left="50%" top="50%" sx={{overflowX: "hidden", transform: `translate(-${width}px, -${height}px);`}}>
            <svg  viewBox="0 0 800 470" width="800" height="470">
                <g transform="translate(414.7937987176738 279.70730316348084)">
                    <path fill="#2b395b" id="blob1" d="M106 -142.9C135.1 -124.7 155.1 -90.8 159.6 -56.6C164.2 -22.5 153.4 12 139.1 41.8C124.7 71.6 106.7 96.9 82.9 125.3C59.2 153.8 29.6 185.4 -13.4 203.9C-56.4 222.3 -112.9 227.7 -140.4 200.5C-168 173.3 -166.7 113.5 -163.7 66.5C-160.7 19.5 -156 -14.8 -157.8 -64.2C-159.6 -113.7 -168 -178.2 -142.8 -197.7C-117.6 -217.1 -58.8 -191.6 -10.2 -177.5C38.4 -163.5 76.8 -161 106 -142.9" ></path>
                    <path fill="#2b395b" id="blob2" style={{visibility: "hidden"}} d="M145.5 -195.9C173.4 -179.9 170.4 -117.2 182.2 -63.3C194 -9.5 220.6 35.4 219.6 81.4C218.5 127.5 189.8 174.6 148.6 203.5C107.4 232.4 53.7 243.2 2 240.5C-49.8 237.8 -99.5 221.7 -124.3 187.4C-149 153.1 -148.7 100.6 -149 58.2C-149.3 15.8 -150.3 -16.7 -142.8 -48.5C-135.3 -80.2 -119.4 -111.3 -94.2 -128.1C-69 -144.9 -34.5 -147.5 12.1 -164.2C58.8 -180.9 117.6 -211.8 145.5 -195.9" ></path>
                    <path fill="#2b395b" id="blob3" style={{visibility: "hidden"}} d="M100.8 -143.5C127.9 -119 145.3 -85.9 154.1 -51.7C162.9 -17.5 163.3 17.8 150.9 46.9C138.4 75.9 113.3 98.6 86 124.6C58.8 150.6 29.4 179.8 -14.7 200C-58.8 220.2 -117.6 231.5 -161.8 211C-206 190.5 -235.7 138.4 -243.9 85.7C-252 33.1 -238.7 -20.1 -221 -71C-203.4 -121.9 -181.3 -170.6 -143.8 -191.7C-106.2 -212.8 -53.1 -206.4 -8.1 -195.2C36.8 -184 73.7 -168.1 100.8 -143.5"></path>
                    <path fill="#2b395b" id="blob4" style={{visibility: "hidden"}} d="M106.3 -137.2C153.9 -111.8 219.6 -102.3 233.3 -72.8C247 -43.4 208.6 6 181.6 49.7C154.6 93.5 139 131.7 110.7 157C82.3 182.3 41.1 194.6 -6.3 203.3C-53.7 211.9 -107.4 216.8 -152.4 196.9C-197.4 177 -233.8 132.4 -233.5 86.8C-233.3 41.2 -196.6 -5.4 -176.6 -54.5C-156.7 -103.6 -153.6 -155.4 -126.9 -187.6C-100.3 -219.7 -50.2 -232.4 -10.4 -218.1C29.4 -203.8 58.8 -162.6 106.3 -137.2"></path>
                    <path fill="#2b395b" id="blob5" style={{visibility: "hidden"}} d="M89.2 -145.5C113 -105.5 127.8 -75.9 140.2 -44.1C152.5 -12.3 162.3 21.8 157.7 56C153.2 90.1 134.2 124.4 105.5 148.9C76.8 173.4 38.4 188.2 -4.3 194.1C-47 200.1 -94 197.1 -119.4 171.5C-144.8 145.9 -148.5 97.7 -166 52C-183.6 6.3 -214.9 -36.9 -219 -85.6C-223 -134.3 -199.7 -188.5 -158.6 -222.8C-117.6 -257.1 -58.8 -271.6 -13 -253.6C32.7 -235.7 65.4 -185.4 89.2 -145.5"></path>
                </g>
            </svg>
        </Box>
        <Box sx={{backgroundColor: color.primary[400], transform: `translate(-${width / 2}px, -${height / 2}px);`}} 
            width={width + "px"} height={height + "px"} 
            position="absolute" left="50%" top="50%" borderRadius="10px"
            display="flex" flexDirection="column" alignItems="center">
            
            <Typography variant="h3" color={color.gray[100]} textAlign="center" mt="30px" fontWeight="bold">Authentication</Typography>
            <Typography align="center" mx="20px" color={color.gray[400]} variant="body1">Please log in with your credentials provided by seanet. If your have any further questions contact the support.</Typography>


            {/* Display that user is already logged in */}
            {logged && <Typography variant="h6" color={color.gray[100]} 
            textAlign="center" m="20px 0" fontWeight="bold" p=".5rem" borderRadius="10px"
            sx={{backgroundColor: color.greenAccent[500], width: "fit"}}>
                You are already logged in.
            </Typography>}

            <Formik onSubmit={sendLogin} initialValues={intialValues} validationSchema={validationSchema}>
                {({values, errors, touched, handleChange, handleBlur, handleSubmit}) => (
                    <form onSubmit={handleSubmit}>
                        <Box display="flex" flexDirection="column" m="0 1rem">
                            <TextField variant="outlined" type="text" label="Username" onBlur={handleBlur} onChange={handleChange}
                                value={values.username} name="username" error={!!touched.username && !!errors.username} helperText={touched.username && errors.username}
                                sx={{borderRadius: "10px", width: "100%", margin: "10px 0"}} />

                            <TextField variant="outlined" type="password" label="Password" onBlur={handleBlur} onChange={handleChange}
                                value={values.password} name="password" error={!!touched.password && !!errors.password} helperText={touched.password && errors.password}
                                sx={{borderRadius: "10px", width: "100%", margin: "10px 0"}} />

                            {secondFactorRequired && <TextField variant="outlined" type="secondFactorCode" label="2FA Code" onBlur={handleBlur} onChange={handleChange}
                                value={values.secondFactorCode} name="secondFactorCode"
                                sx={{borderRadius: "10px", width: "100%", margin: "10px 0"}} />}
                        </Box>
                        <Box display="flex" justifyContent="center" mt="20px">
                            <Button disabled={pending} type="submit" sx={{p: "15px", fontWeight: "bold", borderRadius: "15px"}} variant="contained">
                                {pending ? <CircularProgress size={16}/> : "Submit"}
                            </Button>
                        </Box>
                    </form>
                )}
            </Formik>
        </Box>
    </>
}

export default PanelAuthentication;