import { DarkModeOutlined, LightModeOutlined, Apps, SettingsOutlined, AccountCircle } from "@mui/icons-material";
import { Box, Grid, IconButton, Popover, Switch, Typography, useTheme } from "@mui/material";
import { useContext, useState } from "react";
import { ColorModeContext, getColors } from "../../theme";
import { SettingsContext, useSettings } from "../../components/settingsContext";
import { PanelSessionContext } from "../../components/sessionContext";
import { ConnectRootPermissions } from "../../utils/types";


const TopBar = () => {
    const theme = useTheme();
    const session = useContext(PanelSessionContext);
    const colorMode = useContext(ColorModeContext);
    const colors = getColors(theme.palette.mode);
    const settings = useContext(SettingsContext);

    const [openSettings, setOpenSettings] = useState<boolean>(false);
    const [openApps, setOpenApps] = useState<boolean>(false);

    return <>
        <Box display="flex" justifyContent="space-between" p={2}>
            <Box>
                <Typography variant="h3" fontWeight={"bold"}>SEANET-CONNECT</Typography>
            </Box>
            <Box display="flex">
                <IconButton onClick={e => colorMode.toggleColorMode()}>
                    {theme.palette.mode === "dark" ? <DarkModeOutlined /> : <LightModeOutlined />}
                </IconButton>
                {session.logged?.permissions.includes(ConnectRootPermissions.ROOT.ident) && <IconButton id="settingsTopBarBtn" onClick={e => setOpenSettings(!openSettings)}><SettingsOutlined /></IconButton>}
                <IconButton id="appsTopBar" onClick={() => setOpenApps(!openApps)}><Apps /></IconButton>
            </Box>
        </Box>

        <Popover open={openSettings} onClose={() => setOpenSettings(false)} anchorEl={document.getElementById("settingsTopBarBtn")} anchorOrigin={{horizontal: "center", vertical: "bottom"}}>
            <Box sx={{p: "2rem", width: "20rem"}}>
                <Typography variant="h3" fontWeight={500} sx={{mb: "1rem"}}>Settings</Typography>
                <Box display="flex" flexDirection="column" gap="1rem">
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                        <Typography variant="h6">Development Mode</Typography>
                        <Switch checked={settings.devMode} onChange={e => settings.setDevMode(e.currentTarget.checked)}/>
                    </Box>
                </Box>
            </Box>
        </Popover>
        
        <Popover open={openApps} onClose={() => setOpenApps(false)} anchorEl={document.getElementById("appsTopBar")} anchorOrigin={{ horizontal: "center", vertical: "bottom"}} >
            <Box p="2rem" width="20rem" >
                <Box display="flex" ml="10px" alignItems="center">
                    <AccountCircle sx={{ transform: "scale(2)" }}  />
                    <Typography sx={{ml: "2rem"}}>{session.logged?.name}</Typography>
                </Box>

                <Grid container sx={{ height: "5rem", mt: "2rem" }}>
                    <Grid onClick={() => setOpenApps(false)} item xs={6} sx={{ cursor: "pointer", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", background: colors.blueAccent[900], borderRadius: "15px"}}>
                        <img height="35px" src="/favicon.ico" />
                        <Typography mt="0.5rem" align="center">Connect</Typography>
                    </Grid>

                    <Grid onClick={() => window.location.href = "https://digitalship.eu"} item xs={5} sx={{ cursor: "pointer", display: "flex", flexDirection: "column", ml: "1rem", justifyContent: "center", alignItems: "center"}}>
                        <img height="35px" src="/logos/monitoring.ico" />
                        <Typography mt="0.5rem" align="center">Monitoring</Typography>
                    </Grid>
                </Grid>
            </Box>
        </Popover>
    </>
}

export default TopBar;