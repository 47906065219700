import { Box, Button, ButtonGroup, Dialog, DialogContent, DialogTitle, Modal, TextField, Tooltip, Typography } from "@mui/material"
import { useContext, useEffect, useMemo, useState } from "react"
import { PanelSessionContext } from "../../components/sessionContext";
import { addNotification } from "../global/notificationWrapper";
import { UtilityFunctions } from "../../utils/utilityFunctions";
import axios from "axios";

export const ProfileModals = {
    ChangePassword: () => {
        const session = useContext(PanelSessionContext);

        const [open, setOpen] = useState(false);
        const [password, setPassword] = useState("");
        const [confirm, setConfirm] = useState("")

        const submit = () => {
            if (password !== confirm) return addNotification({ severity: "warning", message: "Passwords do not match"})
            if (password.length < 8 || password.length > 128) return addNotification({ severity: "warning", message: "The passwords length needs to be between 8 and 128 characters"});
            
            axios.post(process.env.REACT_APP_API_URL + "/account/profileUpdate", { password }, { withCredentials: true }).then(res => {
                if (res.data.success) {
                    setOpen(false);
                    addNotification({ severity: "success", message: "Password updated successfully!"});
                } else {
                    console.log("Error deleting asset types ", res.data.error);
                }
            });
        }

        return <>
            <Button onClick={() => setOpen(true)} variant="contained">Change Password</Button>
            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>Change Password</DialogTitle>
                <DialogContent>
                    <Typography>
                        Be aware that this can only be undone by a administrator and you could lock your self out of your account. 
                        Above that only choose secure passwords that contain special characters, numbers and are at least
                        8 characters long.
                    </Typography>

                    <Box display="flex" flexDirection="column" gap={2} mt=".5rem">
                        <TextField type="password" value={password} onChange={e => setPassword(e.currentTarget.value.trim())} placeholder="New password" />
                        <TextField type="password" value={confirm} onChange={e => setConfirm(e.currentTarget.value.trim())} placeholder="Confirm new password" />
                    </Box>

                    <Button variant="contained" sx={{ width: "100%", mt: "1rem"}} onClick={submit}>Submit</Button>
                </DialogContent>
            </Dialog>
        </>
    },

    ClearSessions: () => {
        const session = useContext(PanelSessionContext);

        const [open, setOpen] = useState(false);

        const submit = () => {
            axios.post(process.env.REACT_APP_API_URL + "/account/profileUpdate", { sessionClear: 1 }, { withCredentials: true }).then(res => {
                if (res.data.success) {
                    setOpen(false);
                    addNotification({ severity: "success", message: "Cleared all Sessions successfully!"});
                    UtilityFunctions.logout().then(() => window.location.reload());
                } else {
                    console.log("Error deleting asset types ", res.data.error);
                }
            });
        }

        return <>
            <Button onClick={() => setOpen(true)} variant="contained">Clear Sessions</Button>
            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>Delete all Sessions</DialogTitle>
                <DialogContent>
                    <Typography>
                        Are you sure that you want to delete all session. You will be logged out of your account on every device.
                    </Typography>

                    <ButtonGroup variant="contained" sx={{ mt: "1rem" }}>
                        <Button onClick={submit}>Yes</Button>
                        <Button onClick={e => setOpen(false)}>No</Button>
                    </ButtonGroup>
                </DialogContent>
            </Dialog>
        </>
    },

    ToggleSecondFactor: () => {
        const session = useContext(PanelSessionContext);
        const enabled = useMemo<boolean>(() => !!session.logged?.secondFactor, [session.logged]);

        const [open, setOpen] = useState(false);
        const [token, setToken] = useState<{key:string, url:string}|undefined>();
        const [explicitHidden, setExplicitHidden] = useState<boolean>(true);

        const submit = () => {
            const code = (document.getElementById("2fa-VerificationCode") as HTMLInputElement).value;
            axios.post(`${process.env.REACT_APP_API_URL}/account/2fa`, { order: enabled ? "disable" : "enable", token: token?.key, code}, { withCredentials: true}).then(res => {
                if (res.data.success) {
                    window.location.reload();
                } else {
                    addNotification({ severity: "error", message: res.data.error});
                }
            });
            setOpen(false);
        }

        useEffect(() => {
            if (!session.logged?.secondFactor && !token && open) {
                axios.post(`${process.env.REACT_APP_API_URL}/account/2fa`, { order: "request" }, { withCredentials: true}).then(res => {
                    if (res.data.success) {
                        setToken(res.data);
                    } else {
                        addNotification({ severity: "error", message: "Failed to generate 2FA key on server"});
                    }
                });
            }
        }, [open]);

        return <>
            <Button onClick={() => setOpen(true)} variant="contained">{enabled ? "Disable" : "Enable"} 2 Factor Authentication</Button>
            <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
                <DialogTitle>{enabled ? "Disable" : "Enable"} 2 Factor Authentication</DialogTitle>
                <DialogContent>
                    <Typography>
                        To enable 2-Factor-Authentication scan the QR-Code using your 2FA Wallet App on another device.
                        After you've added the service on your device. Enter the given key and press enable.
                    </Typography>

                    <Typography mt="1rem">
                        If you want to disable the 2-Factor-Authentication simply click on disable at the bottom. Keep in mind that this change will
                        impact the overall security off your account. Please enter your verification code one last time so we know that you are aware
                        of your actions. After disabling 2FA please remove the key at your second factor device. 
                    </Typography>

                    {!enabled && <>
                        <Box display="flex" alignItems="center" gap="2rem" justifyContent="center" mx="3rem" mt="1rem">
                            <img src={token?.url} alt="2fa qr code" />
                            <Box display="flex" gap="2rem" flexDirection="column">
                                <Button onClick={() => setExplicitHidden(!explicitHidden)} sx={{ width: "fit"}} variant="contained">{explicitHidden ? "Show" : "Hide"} Key</Button>
                                <code>{!explicitHidden && token?.key}</code>
                            </Box>
                        </Box>
                        <Typography mt=".75rem">After you scanned the QR-Code please enter the latest code provided by your second device down below and press enable.</Typography>
                    </>}


                    <TextField id="2fa-VerificationCode" placeholder="Verification Code" fullWidth size="small" />


                    <Box display="flex" justifyContent="center">
                        <ButtonGroup variant="contained" sx={{ mt: "2rem"}}>
                            {!enabled ? <Button onClick={submit} color={"success"}>Enable</Button> : <Tooltip title="Not recommended!">
                                <Button onClick={submit} color={"error"}>Disable</Button>    
                            </Tooltip>}
                            <Button onClick={() => setOpen(false)} >Cancel</Button>
                        </ButtonGroup>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    }
}
